export const msalConfig = {
  auth: {
    clientId: "7eafc1bb-aff9-4cb5-9b33-743526940aa2",
    authority: "https://login.microsoftonline.com/9e7851ae-06ab-4a92-92ad-2e35d4fa6fdf", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    //redirectUri: "https://webunikalk.unileasing.cz",
    //redirectUri: process.env.REACT_APP_REDIRECT_URI,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
 scopes: ['7eafc1bb-aff9-4cb5-9b33-743526940aa2/users.read']
};

export const sendMail = {
  scopes: ['https://graph.microsoft.com/Mail.Send']
 };



// export const uniApiConfig = {
//     usersEndpoint: "http://localhost:8080/api/unileasing/users/"
// };
import React, { useState,useEffect } from "react";
import {Accordion,AccordionSummary,AccordionDetails,Typography,Box,Button,Menu,Skeleton} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { pink } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';
import {Images} from "./Images";
import { StyledBadge } from "./styled";
import { UniUploadButton } from "./UniUploadButton";
import { useMsal} from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import AddIcon from '@mui/icons-material/Add';
import {SmazDokument,StahniDokumenty,NahrajDokumenty,StahniDokument} from "../services/FileService";
import MenuItem from '@mui/material/MenuItem';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { NedostupnaSluzba,NeocekavanaChyba,DokumentyOK } from "./StavoveHlasky";
import {isMobile} from 'react-device-detect';
import Fotak2 from "./Fotak2";
import fileDownload from 'js-file-download';
import PDF from '../pdf_small.png';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import UniCircularProgressBar from "./UniCircularProgressBar";
import IconButton from '@mui/material/IconButton';

const styles = {
    accorPopis:{
      fontWeight: 'bold', 
    },
    stavText:{
      lineHeight: '35px',
      fontWeight: 'bold',
      color: 'white',
      backgroundColor: '#8FD14F',
      borderRadius:'8px',
      pl:1,
      pr:1
    },
    nadpis:{
      width: '100px',
      backgroundColor: '#3C486B',
      color: 'white',
      p:"1px",
      borderRadius: '16px',
      mt:"-28px",
      mb:1
    },
    nadpisDoc:{
      width: '200px',
      backgroundColor: '#3C486B',
      color: 'white',
      p:"1px",
      borderRadius: '16px',
      mt:"-65px",
      mb:1,
      position: "absolute"
    },
    sekce:{
      flex:"50%",
      p:2,
      ml:1,
      mr:1,
      mt:1,
      mb:2,
      borderRadius: '16px',
      borderColor: '#3C486B',
    },
    uniokbadge:{
      "& .MuiBadge-badge": {
        color: 'white',
        backgroundColor: '#088443',
      }
    },
    uniproblembadge:{
      "& .MuiBadge-badge": {
          color: 'white',
        backgroundColor: '#a52b71',
    }
}   
};


const Vzory = 
['Kupní smlouva',//0
'Leasingová smlouva',//1
'Úvěrová smlouva',//2
'Faktura',//3
'Protokol o předání',//4
'Splátkový kalendář',//5
'Plán splátek',//6
'Prohlášení ručitele',//7
'Smlouva o ZPVP',//8
'Protokol o kontrole',//9
'Dohoda o převzetí dluhu',//10
'Potvrzení o platbě',//11
'Velký TP',//12
'Malý TP',//13
'COC list',//14
'Zelená karta',//15
'Žádost o čerpání',//16
'Prohlášení o započtení',//17
'Smlouva o budoucí smlouvě kupní',//18
'Příloha smlouvy o budoucí smlouvě kupní',//19
'Jiný dokument',//20
'Záloha smlouvy'//21
]

const AddFileComponent = (props) => {
  const { instance,accounts } = useMsal();
  let [uploadingDocs,setUploadingDocs] = useState(false);
  let [statusUploadDocs,setStatusUploadDocs] = useState(0);
  let [statusDeleteDocs,setStatusDeleteDocs] = useState(0);
  let [error,setError] = useState();
  let [expanded,setExpanded] =  useState(props.data && props.data.length>0);

    const request = {
        ...loginRequest,
        account: accounts[0]
      };

    const handleUploadedDocs = (files,typ) => {
        setUploadingDocs(true);
        instance.acquireTokenSilent(request).then((response) => {
            NahrajDokumenty(response.accessToken,props.cislols,typ,files).then(docs => {
            setStatusUploadDocs(docs.status);
            if (docs.data && docs.status === 200) {
              props.setDocData(docs.data);
              //console.log("here");
              //pripravData(docs.data);
            }
            if (docs.data && docs.status > 200) setError(docs.data); 

            setUploadingDocs(false);  
          }); 
          }).catch((e) => {	  	
            instance.acquireTokenPopup(request).then((response) => {
                NahrajDokumenty(response.accessToken,props.cislols,typ,files).then(docs => {
                  setStatusUploadDocs(docs.status);  
                  if (docs.data && docs.status === 200) {
                    props.setDocData(docs.data);
                    //pripravData(docs.data);
                  }
                  if (docs.data && docs.status > 200) setError(docs.data);   
                  setUploadingDocs(false);        
                }); 
            });
          });
        props.setDocComponents([]);
    }
 
    const handleDeleteDoc = (soubor) => {
        //console.log(soubor);
        instance.acquireTokenSilent(request).then((response) => {
            SmazDokument(response.accessToken,props.cislols,soubor).then(docs => {
              setStatusDeleteDocs(docs.status);
              if (docs.data && docs.status === 200) {
                props.setDocData(docs.data);
                //pripravData(docs.data);
              } 
              if (docs.data && docs.status > 200) setError(docs.data); 
              setUploadingDocs(false); 
          }); 
          }).catch((e) => {	  	
            instance.acquireTokenPopup(request).then((response) => {
                SmazDokument(response.accessToken,props.cislols,soubor).then(docs => {
                  setStatusDeleteDocs(docs.status);
                  if (docs.data && docs.status === 200) {
                    props.setDocData(docs.data);
                    //pripravData(docs.data);
                  } 
                  if (docs.data && docs.status > 200) setError(docs.data); 
                  setUploadingDocs(false); 
                }); 
            });
          });
          setStatusUploadDocs(0);
    }

    const stahniSoubor=(accessToken,id,nazev)=> {
       StahniDokument(accessToken,props.cislols,id).then(souborPdf => {
         if (souborPdf.data && souborPdf.status === 200) fileDownload(souborPdf.data, nazev);
         if (souborPdf.data && souborPdf.status > 200) setError(souborPdf.data);       
       });                  
   }

    const pripravStazeniSouboru = (id,nazev) => {
      try {
        instance.acquireTokenSilent(request).then((response) => {
        stahniSoubor(response.accessToken,id,nazev);
      }).catch(() => {
        instance.acquireTokenPopup(request).then((response) => {
          stahniSoubor(response.accessToken,id,nazev);
        });
      });
      } catch(e) {
        console.log(e);
      }   
    }

    useEffect(() => {
     (props.data && props.data.length > 0) ? setExpanded(false) : setExpanded(true);
    },[props.data]);
    

    function zobrazSoubory(data) {
      let pdfka = [];
      let obrazky = [];
      for(let i=0;i< data.length;i++) {
        if (data[i].nazev.split('.').pop().toLowerCase() === 'pdf') {
          pdfka.push(data[i]);
        } else {
          obrazky.push(data[i]);
        }
      }

      
      return (
        <div>
        {pdfka.map((pdf) => (
          <Box key={pdf.nazev} sx={{display:"flex",alignItems:"center",gap:1}}>
            <img src={PDF} alt="pdf" />
            <Typography>{pdf.nazev}</Typography>
            {isMobile ?
            <div>
              <IconButton sx={{p:0,mr:1}} aria-label="download" onClick={()=> pripravStazeniSouboru(pdf.id,pdf.nazev)}>
                <FileDownloadOutlinedIcon sx={{color:'#dd9234'}} /> 
              </IconButton>
              <IconButton sx={{p:0}} aria-label="delete" onClick={()=> handleDeleteDoc(pdf.id)}>
                <DeleteOutlinedIcon sx={{color:'#dd9234'}} /> 
              </IconButton>
            </div>
            :     
            <div>
              <Button component="label" variant="outlined" startIcon={<FileDownloadOutlinedIcon sx={{color:'#dd9234'}} />} sx={{borderColor:'#dd9234',color:'#dd9234', mb:1,mr:1 }} onClick={()=> pripravStazeniSouboru(pdf.id,pdf.nazev)}>Stáhnout</Button>  
              <Button component="label" variant="outlined" startIcon={<DeleteOutlinedIcon sx={{color:'#dd9234'}} />} sx={{borderColor:'#dd9234',color:'#dd9234', mb:1 }} onClick={()=> handleDeleteDoc(pdf.id)}>Smazat</Button>              
            </div>
            }
          </Box>        
        ))}
        <Images files={obrazky} handleDeleteFile={handleDeleteDoc} mazani={true} />
      </div>
      );
    }    

return (
  <div>
      <div>{statusUploadDocs === -1 || statusDeleteDocs === -1 ? <NedostupnaSluzba/>:<p></p>}</div>
     
  
      <Accordion disableGutters sx={{border: '1px solid black',borderColor:'#3C486B'}} expanded={expanded}  >
         
      <AccordionSummary onClick={() => setExpanded(!expanded)} expandIcon={<ExpandMoreIcon />} aria-controls="panel4-content" id="panel4-header">
      <Typography align="center" sx={styles.nadpisDoc}>{Vzory[props.typ]}</Typography>

        {(props.data && props.data.length > 0) ?
            <Box sx={{display:'flex',flexDirection:'column',mt:'12px'}}>
              <Box sx={{display:'flex',gap:1,flexDirection:'row',alignItems:'center'}}>
                <CheckCircleOutlinedIcon fontSize="medium" color="success"/>
                <Typography sx={{fontStyle:'italic',fontSize:'0.8rem'}}>(Nahraných souborů : {props.data.length})</Typography>
              </Box>

              {statusUploadDocs === 200 ? <DokumentyOK/>:<></>}
              {statusUploadDocs === -1 || statusDeleteDocs === -1 ? <NedostupnaSluzba/>:<></>}
              {statusUploadDocs > 200 || statusDeleteDocs > 200 ? <NeocekavanaChyba chyba={error} />:<></>}
            </Box>
            :
            <Typography sx={{fontStyle:'italic',fontSize:'0.8rem'}}>Nejsou nahrány žádné dokumenty.</Typography>            
          }       

      </AccordionSummary>
      <AccordionDetails>
      <Box>
          <Box sx={{display:"flex",alignItems: "center",mb:2}}>
            {isMobile ? 
              <Fotak2 typ={props.typ} handleUploadedFiles={handleUploadedDocs}/>:<></>
            }
            <UniUploadButton typ={props.typ} pdf={true} handleUploadedFiles={handleUploadedDocs}/> 
            {uploadingDocs ? <UniCircularProgressBar /> : <></> }
          </Box>

          {(props.data && props.data.length > 0) ?
            zobrazSoubory(props.data)
            :
            <></>
        }       
      </Box>        
      </AccordionDetails>
      </Accordion>
      <Box sx={{ mb: 3 }} />
</div>
);
}

export default function Dokumenty(props) { 
    const { instance,accounts } = useMsal();
    let [docData,setDocData] = useState([]);
    let [loading,setLoading] = useState(true);
    let [error,setError] = useState();
    let [anchorEl, setAnchorEl] = useState(null);
    let [docComponents, setDocComponents] = useState([]);
    let [statusDownloadDocs,setStatusDownloadDocs] = useState(0);

    let jeUver = props.cislols.charAt(0) === '7' ? true : false;

    const request = {
        ...loginRequest,
        account: accounts[0]
      };
      
      const open = Boolean(anchorEl);
      
      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };

      const handleClose = () => {
        setAnchorEl(null);
      };

    
    const addComponent = (typ) => {
        const newComponent = <AddFileComponent data={[]} typ={typ} cislols={props.cislols} setDocData={setDocData} setDocComponents={setDocComponents}/>
        setDocComponents(prevComponents => [...prevComponents, newComponent]);
        handleClose();
    };

    useEffect(() => {
        setLoading(true);
        instance.acquireTokenSilent(request).then((response) => {
            StahniDokumenty(response.accessToken,props.cislols).then(docs => {
            setStatusDownloadDocs(docs.status);
            if (docs.data && docs.status === 200) setDocData(docs.data);
            if (docs.data && docs.status > 200) setError(docs.data); 
            setLoading(false);
        }); 
        }).catch((e) => {	  	
          instance.acquireTokenPopup(request).then((response) => {
                StahniDokumenty(response.accessToken,props.cislols).then(docs => {
                  setStatusDownloadDocs(docs.status);
                  if (docs.data && docs.status === 200) setDocData(docs.data);
                  if (docs.data && docs.status > 200) setError(docs.data); 
                  setLoading(false);
              }); 
          });
        });
        // },[instance, accounts]);
      },[]);    

return (
<Accordion>
<AccordionSummary
  expandIcon={<ExpandMoreIcon />}
  aria-controls="panel3a-content"
  id="panel3a-header"
>
  <Typography  sx={styles.accorPopis}>Dokumenty k zaplacení</Typography>
  {loading ? 
  <Skeleton variant="rounded" width={35} height={35} />
:  
<div>


    <Tooltip title="Dokumenty">
      {docData.length > 0 ?
      <StyledBadge sx={styles.uniokbadge} badgeContent={docData.length}>
        <FolderOpenOutlinedIcon sx={{mr:1,p:'2px',color:'white',bgcolor:'#8FD14F',fontSize:'35px',borderRadius:'8px'}} />
      </StyledBadge>
  :
      <StyledBadge sx={styles.uniproblembadge} 
      badgeContent={docData.length} showZero>
        <FolderOpenOutlinedIcon sx={{mr:1,p:'2px',color:'white',bgcolor:pink[500],fontSize:'35px',borderRadius:'8px'}} />
      </StyledBadge>
  }
  </Tooltip>
  </div>  
}

</AccordionSummary>
<AccordionDetails> 
<div>{statusDownloadDocs === -1 ? <NedostupnaSluzba/>:<p></p>}</div>
<div>{statusDownloadDocs > 200 ? <NeocekavanaChyba chyba={error} />:<p></p>}</div>
<div>
 
{Vzory.map((typ, index) => {
  const filteredData = docData.filter(doc => doc.typ===index);

  return (
    <div key={index}>
      { filteredData.length > 0 || (index===21) || ((index===0 || index===1 || index===3 || index===4) && !jeUver) || ((index===2 || index===3 || index===4 || index===6 || index===8|| index===10) && jeUver) ?
     <AddFileComponent data={filteredData} typ={index} cislols={props.cislols} setDocData={setDocData} setDocComponents={setDocComponents}/>
     :
      <></>
      }

    </div>
);
})}

{docComponents.map((docComponent, index) => (
<div key={index}>{docComponent}</div>
))}      

</div>
<div>

<Button 
  id="docs-button"
  aria-controls={open ? 'docs-menu' : undefined}
  aria-haspopup="true"
  aria-expanded={open ? 'true' : undefined}
  onClick={handleClick}
  startIcon={<AddIcon sx={{color:'#dd9234'}} />} 
  sx={{color:'#dd9234'}} 
>
  Přidat další dokument
</Button>
<Menu
  id="docs-menu"
  anchorEl={anchorEl}
  open={open}
  onClose={handleClose}
  MenuListProps={{
  'aria-labelledby': 'docs-button',
  }}
>
{Vzory.map((dokument,index) => (
  <MenuItem key={index} onClick={()=> addComponent(index)} value={index}>{dokument}</MenuItem>
))}
</Menu>
</div>
 
</AccordionDetails>        
</Accordion>     
);
}
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function EmailDialog(props) {
  
  const handleClose = (odeslat) => {
    props.setEmailDialogOpen(false);
    props.setOdeslatEmail(odeslat);
  };

  return (
    <div>
      <Dialog
        open={props.emailDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Odeslat email"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Opravdu si přejete odeslat email na centrálu?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} autoFocus>Ne</Button>
          <Button onClick={() => handleClose(true)}>Ano</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React, { useState,useEffect,useRef} from "react";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import AlertDialog from "./AlertDialog";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Box } from "@mui/material";

export const Images = (props) => {
  let [open, setOpen] = useState(false);
  let [image, setImage] = useState("false");
  let [alertDialogOpen,setAlertDialogOpen] = useState(false);
  let [fileToDelete,setFileToDelete] = useState(-1);
  let [deleteFile,setDeleteFile] =  useState(false);
  const windowWidth = useRef(window.innerWidth);

  const handleClose = () => {
    setOpen(false);
  };
  
  const handleImage = (value) => {
    setImage(value);
    setOpen(true);
    //console.log(window.innerWidth +" "+ window.innerHeight);
  };    

  const handleDeleteFile = (id) => {
    setAlertDialogOpen(true);
    setFileToDelete(id);
  }

  useEffect(() => {
    // console.log("soubor k smazani: "+fileToDelete);
    // console.log("smazat: "+deleteFile);

    if (fileToDelete > 0) {
      props.handleDeleteFile(fileToDelete);
    }
    setFileToDelete(-1);
    setDeleteFile(false);
  },[deleteFile]);


  
  return (
    <div>
    {/* <ImageList sx={{m:1,gridTemplateColumns:"repeat(auto-fill, minmax(280px,1fr))!important"}}> */}
    <ImageList cols={windowWidth.current > 600 ? 2 : 1}>
             {props.files.map((file) => (
              
              <ImageListItem key={file.id}>
                {file.nahled !== null ?
                <div>
                  <img alt={file.nazev} src={`data:image/jpeg;base64,${file.nahled}`} onClick={(e) => handleImage(file.nahled)} />

                  <ImageListItemBar
                    sx={{
                      background:
                      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                    }}
                    position="top"
                    actionIcon={
                      // <IconButton sx={{ color: 'white' }} aria-label={'close'} onClick={()=> props.handleDeleteFile(file.fileName)}>            
                    <IconButton sx={{ color: 'white',visibility:props.mazani ? "inherit": "hidden" }} aria-label={'close'} onClick={() => handleDeleteFile(file.id)}>
                      <DeleteIcon/>
                    </IconButton>
                    }
                  actionPosition="right"
                  />   
              </div>
              :
              <Box sx={{display:"flex",alignItems:"center"}}><ErrorOutlineOutlinedIcon color="error" sx={{mr:1}}/><i>Soubor <b>{file.nazev}</b> nenalezen.</i></Box>
              }             
              </ImageListItem>
))}
  </ImageList>
  <AlertDialog alertDialogOpen={alertDialogOpen} setAlertDialogOpen={setAlertDialogOpen} setDeleteFile={setDeleteFile}></AlertDialog>
  <Modal 
    open={open} 
    onClose={handleClose} 
    closeAfterTransition 
    sx={{display:"flex",alignItems:"center",justifyContent:"center"}}
    >
    <Fade in={open} timeout={500}>
      <img alt="" src={`data:image/jpeg;base64,${image}`} style={{ maxHeight: "75%", maxWidth: "75%" }}/>
    </Fade>
  </Modal>  
  </div>
  );  
}
import axios from 'axios'

export const GetActiveSml = async (accessToken) => {
   const REST_API_URL = process.env.REACT_APP_SERVER_URL+'/api/unileasing/smlouvy/aktivni';
  	 try {
          const response = await axios.get(REST_API_URL, { headers: {"Cache-Control": "no-cache","Pragma": "no-cache","Expires": "0", "Authorization": `Bearer ${accessToken}` },timeout:process.env.REACT_APP_TIMEOUT });
          //console.log(response);
          return response;
        } catch (error) {
          console.log(error);
          if (error.response) return error.response
          else return {status: -1};    
    } 
}

export const GetActiveSmlNoToken = async () => {
  const REST_API_URL = process.env.REACT_APP_SERVER_URL+ '/api/unileasing/smlouvy/aktivni';
 try {
      const response = await axios.get(REST_API_URL);
      return response.data;
  } catch (error) {
    console.log(error);
    return {status: -1};
  } 
}

export const GetActiveSmlPocetNoToken = async () => {
  const REST_API_URL = process.env.REACT_APP_SERVER_URL+'/api/unileasing/smlouvy/aktivni/pocet';
 try {
      const response = await axios.get(REST_API_URL);
      return response.data;
  } catch (error) {
    console.log(error.response);
    if (error.response) return error.response
    else return {status: -1};
  } 
}

export const VratSmlouvu = async (accessToken, smlouva) => {    
     const REST_API_URL = process.env.REACT_APP_SERVER_URL+'/api/unileasing/smlouvy/'+smlouva;
	 try {
        const response = await axios.get(REST_API_URL, { headers: {"Cache-Control": "no-cache","Pragma": "no-cache","Expires": "0", "Authorization": `Bearer ${accessToken}` },timeout:process.env.REACT_APP_TIMEOUT });
        return response;
    } catch (error) {
      console.log(error.response);
      if (error.response) return error.response
      else return {status: -1};
    } 
}

export const VratSmlouvuNoToken = async (smlouva) => {    
  const REST_API_URL = process.env.REACT_APP_SERVER_URL+'/api/unileasing/smlouvy/'+smlouva;
try {
  const response = await axios.get(REST_API_URL);
  return response.data;
 } catch (error) {
  console.log(error.response);
  if (error.response) return error.response
  else return {status: -1};

 } 
}
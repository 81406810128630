import { Card, Typography,Box,CardActionArea, Stack, Skeleton, Alert } from "@mui/material";
import { Link as RouterLink} from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import React, { useState,useEffect } from "react";
import { pink } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';
import { loginRequest } from "../authConfig";
import SearchIcon from '@mui/icons-material/Search';
import TextField from "@mui/material/TextField";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { StyledBadge } from "./styled";
import InputAdornment from '@mui/material/InputAdornment';
import { useMsal} from "@azure/msal-react";
import { GetActiveSml } from "../services/SmlService";
import { NedostupnaSluzba,NeocekavanaChyba} from "./StavoveHlasky";
import { RiMoneyEuroBoxFill } from "react-icons/ri";
import { IconContext } from "react-icons";

const UniSkeleton = () => {
return (
  <Box sx={{mb:2}}>
  <Box sx={{display: 'flex',flexDirection: 'row',justifyContent:"space-between"}}>
  <Skeleton variant="text" width={100}  sx={{ fontSize: "1rem" }} />
<Box spacing={1}  sx={{display: 'flex',flexDirection: 'row',justifyContent:"center"}}>
<Skeleton variant="rectangular" width={35} height={35} sx={{mr:1,ml:1}} />  
<Skeleton variant="rectangular" width={35} height={35} sx={{mr:1,ml:1}} />
<Skeleton variant="rectangular" width={35} height={35} sx={{mr:1,ml:1}}/>
<Skeleton variant="rectangular" width={35} height={35} sx={{mr:1,ml:1}}/>
<Skeleton variant="rectangular" width={35} height={35} sx={{mr:1,ml:1}}/>
<Skeleton variant="rectangular" width={35} height={35} sx={{mr:1,ml:1}}/>
</Box>
</Box>
<Skeleton variant="text" width={250}  sx={{ fontSize: "1rem" }} />
<Skeleton variant="text" width={300}  sx={{ fontSize: "1rem" }} />  
<Box sx={{display: 'flex',flexDirection: 'row',justifyContent:"space-between"}}>
<Skeleton variant="text" width={100}  sx={{ fontSize: "1rem" }} />
<Skeleton variant="text" width={100}  sx={{ fontSize: "1rem" }} />
</Box>
</Box>
);
}

const styles = {
  uniokbadge:{
    "& .MuiBadge-badge": {
      color: 'white',
      backgroundColor: '#088443',
    }
  },
  uniproblembadge:{
    "& .MuiBadge-badge": {
      color: 'white',
      backgroundColor: '#a52b71',
    }
  },
  unitextfield:{
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white',
  },
  }  
};


export default function Obchodnipripady(props) {
  let [page, setPage] = useState(1);
  let [start, setStart] = useState(0);
  let [smlDataApi,setSmlDataApi] = useState([]);
  let [smlDataFiltered,setSmlDataFiltered] = useState([]);
  let [searchQuery, setSearchQuery] = useState("");  
  let [count,setCount] = useState(0);
  let [status,setStatus] = useState(200);
  let [loading,setLoading] = useState(true);
  const { instance,accounts } = useMsal();

  const PER_PAGE = 50;

  const handleChange = (e, p) => {
    setPage(p);
    setStart((p-1)*PER_PAGE);
  };

  const vratSmlData = (accessToken) => {
     GetActiveSml(accessToken).then(response =>{
      setStatus(response.status);
      if (response.data) {
        let count = Math.ceil(response.data.length / PER_PAGE);
        setCount(count);
        setSmlDataApi(response.data);
        setSmlDataFiltered(response.data);
        if (response.status === 200) props.setPocetSml(response.data.length)
        else props.setPocetSml(0);
      } 
      setLoading(false);
    });    
  }

  useEffect(() => {  
    const request = {
      ...loginRequest,
      account: accounts[0]
    };
    
    instance.acquireTokenSilent(request).then((response) => {
      vratSmlData(response.accessToken);           
    }).catch((e) => {	  	
      instance.acquireTokenPopup(request).then((response) => {
        vratSmlData(response.accessToken);
      });
    });
  },[]);    
  
  useEffect(() => {        
    if (searchQuery==="") {
      setSmlDataFiltered(smlDataApi);
    } else {
      
      setSmlDataFiltered(smlDataApi.filter((smlData => 
       smlData.odb.toLowerCase().includes(searchQuery.toLowerCase()) ||
       smlData.nazev.toLowerCase().includes(searchQuery.toLowerCase()) ||
       smlData.smlouva.includes(searchQuery) ||
       smlData.cisop.toLowerCase().includes(searchQuery.toLowerCase())
         )));
    }
            
  },[searchQuery]);

return (
status===200 ?
<div>
<Box sx={{mb:2}}>
    <TextField
    fullWidth
      id="search-bar"
      className="text"
      onInput={(e) => {
        setSearchQuery(e.target.value);
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        sx: { borderRadius: "16px" } 
      }} 
      sx={styles.unitextfield}     
      variant="outlined"
      placeholder="Vyhledat"
      size="small"  
    />
  </Box>
{loading ?
[...Array(10)].map((e, i) => <UniSkeleton key={i}/>)
:
 smlDataFiltered.length > 0 ? smlDataFiltered.slice(start,start+PER_PAGE).map(
                                 op => 
<Card sx={{borderRadius:'16px',p:2,mb:2}} key={op.smlouva}>
<CardActionArea component={RouterLink} to={"/obchodnipripady/"+op.smlouva}>
<Box sx={{display: 'flex',flexDirection: 'row',justifyContent:"space-between"}}>  

{op.predavajici > 0 ?
      <Box sx={{display:"flex", alignItems:"center"}}>       
        <Typography align="center" variant="subtitle1" sx={{mr:1}}>{op.smlouva}</Typography>
        <Typography variant='caption'>{'('+op.uzivatel+', '+op.mesto+')'}</Typography>
      </Box>
      :
      <Typography align="center" variant="subtitle1">{op.smlouva}</Typography>
      }
<Box sx={{display: 'flex',flexDirection: 'row',justifyContent:"center"}}>

<Tooltip title={op.stavText}>
{op.stavop !== 'EX' ?
  <Typography variant="h6" sx={{textAlign: 'center',verticalAlign: 'middle',lineHeight: '35px',borderRadius:'8px',width:"35px", backgroundColor: '#F29400',color: 'white',mr:1,ml:1,fontWeight:'bold'}}>{op.stavop}</Typography>
  :
  <Typography variant="h6" sx={{textAlign: 'center',verticalAlign: 'middle',lineHeight: '35px',borderRadius:'8px',width:"35px", backgroundColor: '#8FD14F',color: 'white',mr:1,ml:1,fontWeight:'bold'}}>{op.stavop}</Typography>
}
</Tooltip>


  {op.zaplaceno > 0 ?
    <Box sx={{mr:1,backgroundColor:"#8FD14F",width:"35px",height: "35px",lineHeight: "35px",borderRadius:"8px"}}>
      <IconContext.Provider value={{ style: { display: 'block',height:'100%',margin:'auto' } }}>
        <RiMoneyEuroBoxFill color="white" size="25px"/>
        </IconContext.Provider>
      </Box> 
      :
      // <Box sx={{mr:1,backgroundColor:pink[500],width:"35px",height: "35px",lineHeight: "35px",borderRadius:"8px"}}>
      //   <IconContext.Provider value={{ style: { display: 'block',height:'100%',margin:'auto' } }}>
      //     <RiMoneyEuroBoxFill color="white" size="25px"/>
      //   </IconContext.Provider>
      // </Box>      
      <></>
  }

{
op.zast1PropadlaIdent || op.zast2PropadlaIdent ?
<Tooltip title="Identifikace">
  {/* <Typography align="center" sx={{ width:"25px",backgroundColor: pink[500],color: 'white',p: 0.5,mr:1,fontWeight:'bold'}}>I</Typography> */}
  <InfoOutlinedIcon sx={{mr:1,p:'2px',color:'white',bgcolor:pink[500],fontSize:'35px',borderRadius:'8px'}} />
</Tooltip>
:
<Tooltip title="Identifikace">
  {/* <Typography align="center" sx={{ width:"25px",backgroundColor: '#8FD14F',color: 'white',p: 0.5,mr:1,fontWeight:'bold'}}>I</Typography> */}
  <InfoOutlinedIcon sx={{mr:1,p:'2px',color:'white',bgcolor:'#8FD14F',fontSize:'35px',borderRadius:'8px'}} />
  </Tooltip>
}
{op.fotografie > 2 ?
<Tooltip title="Fotografie">
  <StyledBadge sx={styles.uniokbadge} badgeContent={op.fotografie}>
    {/* <Typography align="center" sx={{ width:"25px",backgroundColor: '#8FD14F',color: 'white',p: 0.5,mr:1,fontWeight:'bold'}}>F</Typography> */}
    <PhotoCameraOutlinedIcon sx={{mr:1,p:'2px',color:'white',bgcolor:'#8FD14F',fontSize:'35px',borderRadius:'8px'}} />
  </StyledBadge>
</Tooltip>
:
<Tooltip title="Fotografie">
  <StyledBadge sx={styles.uniproblembadge} badgeContent={op.fotografie} showZero>
    {/* <Typography align="center" sx={{ width:"25px",backgroundColor: pink[500],color: 'white',p: 0.5,mr:1,fontWeight:'bold'}}>F</Typography> */}
    <PhotoCameraOutlinedIcon sx={{mr:1,p:'2px',color:'white',bgcolor:pink[500],fontSize:'35px',borderRadius:'8px'}} />
  </StyledBadge>
</Tooltip>
}
{op.kzaplaceni > 0 ?
<Tooltip title="Dokumenty">
  <StyledBadge sx={styles.uniokbadge} badgeContent={op.kzaplaceni}>
    {/* <Typography align="center" sx={{ width:"25px",backgroundColor: '#8FD14F',color: 'white',p: 0.5,mr:1,fontWeight:'bold'}}>D</Typography> */}
    <FolderOpenOutlinedIcon sx={{mr:1,p:'2px',color:'white',bgcolor:'#8FD14F',fontSize:'35px',borderRadius:'8px'}} />
  </StyledBadge>
</Tooltip>
:
<Tooltip title="Dokumenty">
  <StyledBadge sx={styles.uniproblembadge} badgeContent={op.kzaplaceni} showZero>
    <FolderOpenOutlinedIcon sx={{mr:1,p:'2px',color:'white',bgcolor:pink[500],fontSize:'35px',borderRadius:'8px'}} />
  </StyledBadge>
</Tooltip>

}
{/* <Tooltip title="Pojištění">
  <DescriptionOutlinedIcon sx={{p:'2px',color:'white',bgcolor:'#E0E0E0',fontSize:'35px',borderRadius:'8px'}} />
</Tooltip> */}
</Box>
</Box>
<Typography align="left" variant="h5" sx={{color:"#003883",fontWeight:"bold", overflow:"hidden",whiteSpace: "nowrap",textOverflow: "ellipsis"}}>{op.odb}</Typography>
<Typography align="left" variant="h6" sx={{overflow:"hidden",fontWeight:"bold",whiteSpace: "nowrap",textOverflow: "ellipsis"}}>{op.nazev}</Typography>
<Box sx={{display: 'flex',flexDirection: 'row',justifyContent:"space-between"}}>
<Typography align="left" variant="subtitle1">{op.cisop}</Typography>
<Tooltip title="Datum předání">
<Typography align="left" variant="subtitle1">{op.dpredani}</Typography>
</Tooltip>
</Box>
</CardActionArea> 
</Card>
) : <div><i>Nenalezen žádný záznam.</i></div>
}

{count > 1 ?
<Stack spacing={2}>
<Pagination sx={{display: 'flex',justifyContent:"center"}} count={count} size="large" page={page} variant="outlined" shape="rounded" onChange={handleChange}/>
</Stack>
:
<></>
}
</div>  
: 
<div>
<div>{status === -1 ? <NedostupnaSluzba/>:<p></p>}</div>
<div>{status > 200 ? <NeocekavanaChyba chyba={smlDataApi}/>:<p></p>}</div> 
</div>
);
}
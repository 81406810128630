import {Accordion,AccordionSummary,AccordionDetails,Typography,Box,Button,Menu,CircularProgress,Alert,Skeleton} from '@mui/material';


function UniCircularProgressBar() {

return (
    <CircularProgress size={25} sx={{ml:1}} />
);  
}

export default UniCircularProgressBar;
import { Paper, Typography,Box,Skeleton} from "@mui/material";
import { useEffect,useState } from "react";
import { VratUzivatele } from "../services/UserService";
import { loginRequest } from "../authConfig";
import { useMsal} from "@azure/msal-react";
import { NedostupnaSluzba,NeocekavanaChyba } from "./StavoveHlasky";

const UniSkeleton = () => {
  return (
  <Paper variant="outlined" sx={styles.sekce}>
    <Typography align="center"  sx={styles.nadpis}>Přihlášený uživatel</Typography>    
    <Skeleton variant="text" width={100}  sx={{fontSize: "1rem" }} />
    <Skeleton variant="text" width={250}  sx={{fontSize: "1rem" }} />
    <Skeleton variant="text" width={250}  sx={{fontSize: "1rem" }} />
    <Skeleton variant="text" width={250}  sx={{fontSize: "1rem" }} />
    <Skeleton variant="text" width={250}  sx={{fontSize: "1rem" }} />
  </Paper>
  );
  }

const styles = {
  nadpis:{
    width: '200px',
    backgroundColor: '#dd9234',
    color: 'white',
    p:"1px",
    borderRadius: '16px',
    mt:"-28px",
    mb:1
  },
  sekce:{
    flex:"50%",
    backgroundColor:'#F0F0F0',
    p:2,
    m:1,
    mb:2,
    borderRadius: '16px',
    borderColor: '#dd9234',
  }
};

export const Nastaveni=() => {
  let [uzivatel,setUzivatel] = useState([]);
  let [loading,setLoading] = useState(true);
  let [status,setStatus] = useState(200);
  const { instance,accounts } = useMsal();
  
  useEffect(() => {                  
    const request = {
        ...loginRequest,
        account: accounts[0]
    };
    
      instance.acquireTokenSilent(request).then((response) => {
        console.log(response.accessToken);
        vratApiData(response.accessToken);           
        setLoading(false);
      }).catch((e) => {	  	
      instance.acquireTokenPopup(request).then((response) => {
        vratApiData(response.accessToken);
      });
    });
  },[instance, accounts]);  

  const vratApiData = (accessToken) => {
    VratUzivatele(accessToken).then(response =>{
      //console.log(response);
      setStatus(response.status);
      if (response.data) setUzivatel(response.data);
    });    
  }


return (
status === 200 ?
<div>
<div>{status === -1 ? <NedostupnaSluzba/>:<p></p>}</div>
<Box sx={{display:"flex", flexDirection: {xs: 'column', sm: 'row'},mt:2}}>
{loading ? 
  <UniSkeleton/>
  :
  <Paper variant="outlined" sx={styles.sekce}>
    <Typography align="center"  sx={styles.nadpis}>Přihlášený uživatel</Typography>            
    <Typography>ID uživatele: {uzivatel.pracovnik}<br/>
      Jméno, příjmení: <Box component="span" fontWeight="bold" color="#dd9234">{uzivatel.jmeno} {uzivatel.prijmeni}</Box><br/>
      Pobočka: {uzivatel.pobocka_text}<br/>
      Datum narození: {uzivatel.narozeni}<br/>
      Telefon: {uzivatel.telefon}<br/>
      Mobil: {uzivatel.mobil}<br/>
    </Typography>
  </Paper>
}
<Paper variant="outlined" sx={styles.sekce}>
<Typography align="center" sx={styles.nadpis}>Zákaznická podpora</Typography>            
<Typography>V případě problémů kontaktujte:</Typography><br/>

  <Typography sx={{color:"#dd9234",fontWeight:"bold"}}>Jan Krahulec</Typography>
  <Typography>krahulec@unileasing.cz<br/>(+420) 702 202 714</Typography><br/>
  <Typography sx={{color:"#dd9234",fontWeight:"bold"}}>Josef Vlček</Typography>
  <Typography>vlcek@unileasing.cz<br/>(+420) 602 176 806</Typography><br/>
  <Typography sx={{color:"#dd9234",fontWeight:"bold"}}>Milan Resch</Typography>
  <Typography>resch@unileasing.cz<br/>(+420) 724 287 384</Typography>
  
</Paper>
</Box>
</div>
:
<div>
<div>{status === -1 ? <NedostupnaSluzba/>:<p></p>}</div>
<div>{status > 200 ? <NeocekavanaChyba chyba={uzivatel}/>:<p></p>}</div> 
</div>
);
}
import axios from 'axios'

export const OdesliMail = async (accessToken,email,zprava) => {    
    const REST_API_URL = 'https://graph.microsoft.com/v1.0/users/'+email+'/sendMail';
  try {
    const response = await axios.post(REST_API_URL,zprava, { headers: {"Content-Type":"application/json","Authorization" : `Bearer ${accessToken}`},timeout:process.env.REACT_APP_TIMEOUT });
    //console.log(response);
    return response;
   } catch (error) {
    console.log(error);
    if (error.response) return error.response
    else return {status: -1}; 
   } 
  }
import {useNavigate,useLocation, Routes, Route} from 'react-router-dom';
import Obchodnipripady from './Obchodnipripady';
import Obchodnipripad from './Obchodnipripad';
import { Container,Box, Typography } from '@mui/material';
import { Nastaveni } from './Nastaveni';
import { useEffect, useState } from "react";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import IconButton from '@mui/material/IconButton';
import Dashboard from './Dashboard';


export const ObsahPrihlasen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [popisek, setPopisek] = useState("");
  const [navigace, setNavigace] = useState("");
  const [pocetSml,setPocetSml] = useState(0);

  
  useEffect(() => {   
    if (location.pathname.match("/obchodnipripady/+")) {
      setNavigace("/obchodnipripady");
      setPopisek("Obchodní případy");
    } else if (location.pathname==="/obchodnipripady") {
      setNavigace("/");
      setPopisek("Obchodní případy ("+pocetSml+")");
      //setPopisek("Obchodní případy");
    } else if (location.pathname==="/nastaveni") {
      setNavigace("/");
      setPopisek("Nastavení");
    }
  });

return (
  
<Box>
 {location.pathname !== "/" ?
<Box sx={{display: 'flex',flexDirection: 'row'}}>
<IconButton sx={{fontSize: 40,color:"white"}} aria-label="navigate" onClick={() => navigate(navigace)}>
  <KeyboardBackspaceIcon fontSize="large" />
</IconButton>
<Typography align="justify" variant="h5" sx={{color:"white",m: 'auto 0 auto 0'}} >{popisek}</Typography>
</Box>
:
<></>
} 
  
<Container maxWidth="lg" sx={{mt:1,pt:1,pb:1, bgcolor: "#d9d9d9",borderTopLeftRadius: '16px',borderTopRightRadius:'16px',minHeight:"90vh"}}>
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/obchodnipripady" element={<Obchodnipripady setPocetSml={setPocetSml}/>} />
      {/* <Route path="/obchodnipripady" element={<Obchodnipripady/>} /> */}
      <Route path="/obchodnipripady/:cislols" element={<Obchodnipripad/>} />
      <Route path="/nastaveni" element={<Nastaveni/>} />
    </Routes>           
</Container>
</Box>
);
}
import React, { useEffect, useState } from 'react';
import 'react-html5-camera-photo/build/css/index.css';
import { Box  } from '@mui/material';
import {Button} from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import { saveAs } from 'file-saver';
import { useOrientation } from 'react-use';
import Compressor from 'compressorjs';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function Fotak2 (props) {
  let [pocet,setPocet] = useState(0);
  let [photos,setPhotos] = useState([]);
  let [open, setOpen] = useState(false);
  let handleOpen = () => setOpen(true);
  const { type } = useOrientation(); 
      
  function handleTakePhoto (files) {
    let currentDate = new Date();  
    let datum = currentDate.getFullYear()+''+
    (currentDate.getMonth()+1)+''+
    ('0'+currentDate.getDate()).slice(-2)+'_'+
    ('0'+currentDate.getHours()).slice(-2)+''+
    ('0'+currentDate.getMinutes()).slice(-2)+''+
    ('0'+currentDate.getSeconds()).slice(-2);

    let nazev = datum+'.'+files[0].name.split('.').pop();
    let soubor = renameFile(files[0],nazev);

    setPhotos(oldPhotos => [...oldPhotos,soubor]);    
    setPocet(pocet+1);

    saveAs(soubor);  
  }

  function renameFile(originalFile, newName) {
    return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
    });
  }

  const handleClick = () => {
    handleOpen();
  }

  const handleUploadedFiles = () => {
    
    let compressedImages = [];
    compressImages(photos, compressedImages).then(() => {
      //console.log(compressedImages);
      if(props.typ=== -1) {
        props.handleUploadedFiles(compressedImages);
      } else {
        props.handleUploadedFiles(compressedImages,props.typ);
      }
    }
    );
    handleClose();
  }

  async function compressImages(files, output){
    return new Promise(async function(resolve){
        let numProcessedImages = 0
        let numImagesToProcess = files.length;
        for (let i = 0; i < numImagesToProcess; i++){
            const file = files[i];
            if (file.name.split('.').pop().toLowerCase() === 'pdf') {
              //console.log('pdf');
              output.push(file);
            } else {
              //console.log('image');
              await new Promise(resolve =>{
                  new Compressor(file, {
                      quality: 0.8,
                      retainExif: true,
                      success(result){                        
                          output.push(new File([result], file.name))
                          resolve()
                      }
                  })
              })
            }

            numProcessedImages += 1
        }
        if (numProcessedImages === numImagesToProcess){
            resolve()
        }
    })
  }

  const handleClose = () => {
    setPocet(0);
    setPhotos([]);
    setOpen(false);
  };

  return (
<div>
<Dialog
  fullScreen
  open={open}
  onClose={handleClose}
>
  <IconButton
    aria-label="close"
    onClick={handleClose}
    sx={{
      position: 'absolute',
      right: 8,
      top: 8,
      //color: (theme) => theme.palette.grey[500],
    }}
  >
    <CloseIcon />
  </IconButton>                
  <Box sx={{  position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)', width: '98vw', bgcolor: 'background.paper',
              border: 'solid #dd9234', boxShadow: 24, p: 4, display:'flex', justifyContent:'center', alignItems:'center',  borderRadius:'8px',
              height:type==='portrait-primary' ? '25vh':'50vh'}}>
    <Box>
      <Button component="label" variant="outlined" startIcon={<PhotoCameraOutlinedIcon sx={{color:'#dd9234'}} />} sx={{borderColor:'#dd9234',color:'#dd9234', mr:1 }} >
        Další foto
        <input type="file" accept="image/*" capture="environment" multiple hidden onChange={(e)=> handleTakePhoto(e.target.files)} />
      </Button>
      <Button component="label" variant="outlined" startIcon={<FileUploadOutlinedIcon sx={{color:'#dd9234'}} />} sx={{borderColor:'#dd9234',color:'#dd9234'}} onClick={handleUploadedFiles}>
        Nahrát ({pocet})
      </Button>    
    </Box>
  </Box>
</Dialog>          
  <Button component="label" variant="outlined" startIcon={<PhotoCameraOutlinedIcon sx={{color:'#dd9234'}} />} sx={{borderColor:'#dd9234',color:'#dd9234', mr:1}} onClick={handleClick}>
    Vyfotit
  <input type="file" accept="image/*" capture="environment" multiple hidden onChange={(e)=> handleTakePhoto(e.target.files)} />
  </Button>
  </div>          

  );
}

export default Fotak2;
import React, { useState,useEffect } from "react";
import {Typography,Box,Skeleton} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles'
import {useParams} from "react-router-dom";
import {VratSmlouvu} from "../services/SmlService";
import Detail from "./Detail";
import Identifikace from "./Identifikace";
import { useMsal} from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import Dokumenty from "./Dokumenty";
import Fotografie from "./Fotografie";
import Pojisteni from "./Pojisteni";
import { NedostupnaSluzba,NeocekavanaChyba } from "./StavoveHlasky";
import { Store } from 'react-notifications-component';
import Pojisteni3 from "./Pojisteni3";
import {
  useSearchParams
} from "react-router-dom"


const UniSkeleton = () => {
  return (
  <Box>
    <Skeleton variant="text" width={100}  sx={{mb:2, fontSize: "1rem" }} />
    <Skeleton variant="text" width={250}  sx={{ fontSize: "1rem" }} />
    <Skeleton variant="text" width={300}  sx={{mb:2, fontSize: "1rem" }} />  

    <Skeleton variant="rounded" sx={{borderRadius: "16px",mb:1 }} height={75} />
    <Skeleton variant="rounded" sx={{borderRadius: "16px",mb:1 }} height={75} />
    <Skeleton variant="rounded" sx={{borderRadius: "16px",mb:1 }} height={75} />
    <Skeleton variant="rounded" sx={{borderRadius: "16px",mb:1 }} height={75} />
  {/* <Skeleton variant="rounded" sx={{borderRadius: "16px",mb:1 }} height={75} /> */}
  </Box>
  );
  }

const theme = createTheme({
  components: {

    MuiAccordionSummary: {
      styleOverrides: {
       
        content: {
          justifyContent: 'space-between',
          height: '50px',
          lineHeight: '50px!important',
          alignItems: 'center',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root:{
          borderRadius: "16px!important",
          marginBottom: "10px",
          "&.MuiAccordion-root:before": {
            display: "none"
          },
        },
        
        content: {
          height: '50px',
        }
      }
    },
    MuiAccordionDetails: {
     styleOverrides: {
      root:{
        paddingLeft: 8,
        paddingRight: 8,
      },
      content:{
        
      }
     } 
    }
  },
});

export default function Obchodnipripad() {
  const { instance,accounts } = useMsal();
  let { cislols } = useParams();
  let [sml,setSml] = useState([]);
  let [status,setStatus] = useState(200);
  let [loading,setLoading] = useState(true);
  const [queryParameters] = useSearchParams();


  const request = {
    ...loginRequest,
    account: accounts[0]
  };
  
  
  const nactiSml=(accessToken)=> {
    VratSmlouvu(accessToken,cislols).then(smlTmp => {
      setStatus(smlTmp.status);
      if (smlTmp.data) {
        setSml(smlTmp.data);
        if (smlTmp.data.zaplaceno === 1) {
          Store.addNotification({
            //title: "Realizována platba!",
            message: "Na smlouvě "+ smlTmp.data.smlouva +" byla realizována platba.",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000
          }
        });
        }
      }
    });                  
  }
  
  useEffect(() => {
    instance.acquireTokenSilent(request).then((response) => {
      nactiSml(response.accessToken);
    }).catch((e) => {	  	
      instance.acquireTokenPopup(request).then((response) => {
        nactiSml(response.accessToken);
      });
    });
    setLoading(false);


    // },[instance, accounts]);
  },[]);
  
  return (

    status === 200 ?
    <div>  
      <div>{status === -1 ? <NedostupnaSluzba/>:<p></p>}</div>   
      <div>{status > 200 ? <NeocekavanaChyba chyba={sml}/>:<p></p>}</div> 

      {loading ? 
      <UniSkeleton/>
      :
      <div>

      {queryParameters.get("pojisteni") !== "1" ?
      <div>      
        {sml.predavajici > 0 ?
          <Box sx={{display:"flex", alignItems:"center"}}>
            <Typography variant='subtitle1' sx={{mr:1}}>{sml.smlouva}</Typography>
            <Typography variant='caption'>{'('+sml.uzivatel+', '+sml.mesto+')'}</Typography>
          </Box>
        :
          <Typography variant='subtitle1' sx={{mb:2,mt:1}}>{sml.smlouva}</Typography>
        }

        <Typography align="left" variant="h5" sx={{color:"#003883",fontWeight:"bold", overflow:"hidden",whiteSpace: "nowrap",textOverflow: "ellipsis"}}>{sml.odb}</Typography>
        <Typography align="left" variant="h6" sx={{mb:2,fontWeight:"bold",overflow:"hidden",whiteSpace: "nowrap",textOverflow: "ellipsis"}}>{sml.nazev}</Typography>
      </div>
      :
      <></>
      }
      <ThemeProvider theme={theme}>
      {/* {queryParameters.get("pojisteni") !== "1" ? */}
      <div> 
        <Detail sml={sml} />
        <Identifikace cislols={cislols} idnajemce={sml.gidnajemce} idzastupce={sml.gidzastupce1naj} />
        <Fotografie cislols={cislols}/>
        <Dokumenty cislols={cislols}/>
        <Pojisteni3 cislols={cislols} klient={sml.odb} predmet={sml.nazev} email={sml.odbmail} />    
      </div>  
      {/* : */}
      {/* } */}
      </ThemeProvider>    
      </div>
    


      }
    </div>
    :
  <div>
    <div>{status === -1 ? <NedostupnaSluzba/>:<p></p>}</div>
    <div>{status > 200 ? <NeocekavanaChyba chyba={sml}/>:<p></p>}</div> 
  </div>
  );
}
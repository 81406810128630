import { Box, Paper, Typography } from "@mui/material";

const styles = {
  nadpis:{
    width: '300px',
    backgroundColor: '#3C486B',
    color: 'white',
    p:"1px",
    borderRadius: '16px',
    mt:"-28px",
    mb:1,
    ml:2
  },
  cena:{
    width: '200px',
    backgroundColor: '#3C486B',
    color: 'white',
    p:"1px",
    borderRadius: '16px',
    mt:"-28px",
    mb:1,
    ml:2
  },
  sekce:{
    flex:"50%",
    pt:2,
    ml:1,
    mr:1,
    mt:1,
    mb:2,
    borderRadius: '16px',
    borderColor: '#3C486B',
  },
};

export const VnitrniBox =(props) => {
    return(
    <Box sx={{ display:"flex", flexDirection: {xs: 'column', sm: 'row'}}}>
      <Paper variant="outlined" sx={styles.sekce}>
        <Box sx={{display:"flex"}}>
          <Typography align="center" sx={styles.nadpis}>{props.nadpis}</Typography> 
          {props.cenaCelkem ?
            <Typography align="center" sx={styles.cena}>{props.cenaCelkem}</Typography> 
            :
            <></>
          }
        </Box>
        <Box sx={{pb:2,pl:{xs:0,sm:5},pr:{xs:0,sm:5}}}>
        {props.children}
          </Box>           
     </Paper>    
    </Box> 
    )
}
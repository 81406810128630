import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TextField,Accordion, AccordionDetails, AccordionSummary, Box, Typography,Stack,Alert, Button,FormControlLabel  } from '@mui/material';
import React, {useState,useEffect} from "react";
import { VnitrniBox } from "./VnitrniBox";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses }  from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import PojDialog from './PojDialog';
import UniCircularProgressBar from "./UniCircularProgressBar";
import PDF from '../pdf_small.png';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import { pink } from '@mui/material/colors';
import { cs } from 'date-fns/locale/cs';
import SystemUpdateAltOutlinedIcon from '@mui/icons-material/SystemUpdateAltOutlined';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

const styles = {
    accorPopis:{
      fontWeight: 'bold', 
    },
    stavText:{
      lineHeight: '35px',
      color: 'white',
      backgroundColor: '#8FD14F',
      borderRadius:'8px',
      pl:1,
      pr:1,
      fontWeight:'bold',
    },
    nadpis:{
      width: '100px',
      backgroundColor: '#3C486B',
      color: 'white',
      p:"1px",
      borderRadius: '16px',
      mt:"-28px",
      mb:1
    },
    nadpisDoc:{
      width: '200px',
      backgroundColor: '#3C486B',
      color: 'white',
      p:"1px",
      borderRadius: '16px',
      mt:"-28px",
      mb:1
    },
    sekce:{
      flex:"50%",
      p:2,
      ml:1,
      mr:1,
      mt:1,
      mb:2,
      borderRadius: '16px',
      borderColor: '#3C486B',
    },
    uniokbadge:{
      "& .MuiBadge-badge": {
        color: 'white',
        backgroundColor: '#088443',
      }
    },
    uniproblembadge:{
      "& .MuiBadge-badge": {
        color: 'white',
        backgroundColor: '#a52b71',
      }
    }   
  };

export default function Pojisteni3(props) { 
  let [VIN,setVIN] = useState("W0LMRFCYNGB095227");
  let [RZ,setRZ] = useState("4AN12345");
  let [ORV,setORV] = useState("UBI193526");
  let [progress,setProgress] = useState(false);
  let [progress1,setProgress1] = useState(false);
  let [sjednano,setSjednano] = useState(false);
  let [aktualizovano,setAktualizovano] = useState(false);
  let [datum,setDatum] = useState(new Date());
  let [openLimit,setOpenLimit] = useState(true);
  let [openSpol,setOpenSpol] = useState(true);
  let [openDopln,setOpenDopln] = useState(true);

  let [pojDialogOpen,setPojDialogOpen] = useState(false);
  let [sjednat,setSjednat] =  useState(false);

  const handleSjednat = () => {
    setPojDialogOpen(true);
  }

  const handleAktualizovat = () => {
    setProgress1(true);
    //console.log("sjednat");
    setTimeout(() => {
      setProgress1(false);
      setAktualizovano(true);
    }, 1500);
  }

  useEffect(() => {
    if (sjednat) {
      sjednatPojisteni();
    }
    setSjednat(false);
  },[sjednat]);


  const sjednatPojisteni = () => {
    setProgress(true);
    //console.log("sjednat");
    setTimeout(() => {
      setProgress(false);
      setSjednano(true);
    }, 1500);

  }

return (
<Accordion>
<AccordionSummary
  expandIcon={<ExpandMoreIcon />}
  aria-controls="panel3a-content"
  id="panel3a-header"
>
  <Typography sx={styles.accorPopis}>Pojištění</Typography>
  {/* <Typography>{props.klient}, {props.predmet}</Typography> */}
  {sjednano?
  <DescriptionOutlinedIcon sx={{mr:1,p:'2px',color:'white',bgcolor:'#8FD14F',fontSize:'35px',borderRadius:'8px'}} />
  :
  <DescriptionOutlinedIcon sx={{mr:1,p:'2px',color:'white',bgcolor:pink[500],fontSize:'35px',borderRadius:'8px'}} />
  }
</AccordionSummary>
<AccordionDetails>
  <VnitrniBox nadpis={"Doplňující údaje"}>
  <Box sx={{display:"flex",width:"80%",margin:"auto",gap:{xs:"20px",md:"20%"},flexDirection: {xs: 'column', md: 'row'}}}>
  <Stack
      component="form"
      sx={{
        width: '40%',
      }}
      spacing={2}
      noValidate
      autoComplete="off"
    >

    <FormControlLabel labelPlacement="start" control={
      <TextField
      id="outlined-controlled"
      value={VIN}
      sx={{pl:5}}
      onChange={(event) => {
        setVIN(event.target.value);
      }}
    />
    } label="VIN:"/>   


<FormControlLabel labelPlacement="start" control={
  <TextField
  id="outlined-controlled"
  value={RZ}
  sx={{pl:5}}
  onChange={(event) => {
    setRZ(event.target.value);
  }}
/>
} label="RZ:" />    
    </Stack>    

    <Stack
      component="form"
      sx={{
        width: '40%',
      }}
      spacing={2}
      noValidate
      autoComplete="off"
    >
<FormControlLabel labelPlacement="start" control={
  <TextField
  id="outlined-controlled"
  value={ORV}
  sx={{pl:5}}
  onChange={(event) => {
    setORV(event.target.value);
  }}
/>
} label="ORV:" />   

<FormControlLabel labelPlacement="start" control={
  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={cs}>
      <DatePicker defaultValue={datum} onChange={(newValue) => setDatum(newValue)}  sx={{pl:7,width:"380px"}} />
    </LocalizationProvider>  
} label="Pojistit od:" />  
    </Stack>    
    </Box>
    <Box sx={{ml:"140px",display:"flex",alignItems:"center"}}>
    <Button onClick={handleAktualizovat}  component="label" variant="outlined" startIcon={<SystemUpdateAltOutlinedIcon sx={{color:'#dd9234'}} />} sx={{mt:4,borderColor:'#dd9234',color:'#dd9234'}}>AKTUALIZOVAT</Button>
    {progress1 ?<UniCircularProgressBar /> :<></>} 
  </Box>
  <div>{aktualizovano ? <Alert severity="success">Údaje byly úspěšně aktualizovány!</Alert>:<p></p>}</div>    
  </VnitrniBox>
  <VnitrniBox nadpis={"Shrnutí"}>
  <TableContainer component={Box} sx={{width:"80%",margin:"auto"}}>
      <Table aria-label="poj-table">
        <TableBody>
            <TableRow key={'poj'}>
            <TableCell width={"40px"}>

            </TableCell>              
              <TableCell align="left">Pojišťovna</TableCell>
              <TableCell sx={{fontWeight:"bold"}} align="right">Česká podnikatelská pojišťovna a.s.</TableCell>
            </TableRow>
            <TableRow key={'pov'}>
            <TableCell width={"40px"}>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpenLimit(!openLimit)}
              >
              {openLimit ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
              <TableCell align="left">Povinné ručení</TableCell>
              <TableCell sx={{fontWeight:"bold"}} align="right">14 160 Kč</TableCell>
            </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                  <Collapse in={openLimit} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Table sx={{[`& .${tableCellClasses.root}`]: {borderBottom: "none"}}} size="small" aria-label="pov-limit">
                        <TableBody>
                          <TableRow key={"pov-limit"}>
                            <TableCell width={"75px"}></TableCell>
                            <TableCell align='left'>
                            Limit: 100/100 mil. Kč
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>  
            <TableRow key={'hav'}>
            <TableCell width={"40px"}>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpenSpol(!openSpol)}
              >
              {openLimit ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
              </TableCell>
              <TableCell align="left">Havarijní pojištění</TableCell>
              <TableCell sx={{fontWeight:"bold"}} align="right">23 556 Kč</TableCell>
            </TableRow>  
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                  <Collapse in={openSpol} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Table sx={{[`& .${tableCellClasses.root}`]: {borderBottom: "none"}}} size="small" aria-label="pov-limit">
                        <TableBody>
                          <TableRow key={"hav-spol"}>
                            <TableCell width={"75px"}></TableCell>
                            <TableCell  align='left'>
                            Spoluúčast: 5%, minimálně však 5 000 Kč
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>


            <TableRow key={'dopln'}>
            <TableCell width={"40px"}>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpenDopln(!openDopln)}
              >
              {openLimit ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
              </TableCell>
              <TableCell align="left">Doplňkové pojištění</TableCell>
              <TableCell sx={{fontWeight:"bold"}} align="right">675 Kč</TableCell>
            </TableRow>  
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                  <Collapse in={openDopln} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Table sx={{[`& .${tableCellClasses.root}`]: {borderBottom: "none"}}} size="small" aria-label="pov-limit">
                        <TableBody>
                          <TableRow key={"dopln"}>
                            <TableCell width={"75px"}></TableCell>
                            <TableCell >
                            Čelní sklo
                            </TableCell>
                            <TableCell align="right">675 Kč</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>                                    
            <TableRow key={'celkem'}>
            <TableCell align="left"></TableCell>
              <TableCell sx={{fontSize:"16px",fontWeight:"bold"}} align="left">Celkem</TableCell>
              <TableCell sx={{fontSize:"16px",fontWeight:"bold"}} align="right">38 391 Kč</TableCell>
            </TableRow>                                      
                        
        </TableBody>
      </Table>
  <Box sx={{display:"flex",alignItems:"center"}}>
    <Button onClick={handleSjednat} component="label" variant="outlined" startIcon={<HandshakeOutlinedIcon sx={{color:'#dd9234'}} />} sx={{mt:2,mb:2,borderColor:'#dd9234',color:'#dd9234'}}>SJEDNAT</Button>
    {progress ?<UniCircularProgressBar /> :<></>} 
  </Box>
  <div>{sjednano ? <Alert severity="success">Pojištění bylo úspěšně sjednáno!</Alert>:<p></p>}</div>
  <PojDialog pojDialogOpen={pojDialogOpen} setPojDialogOpen={setPojDialogOpen} setSjednat={setSjednat}></PojDialog>
  {sjednano ?
  <Box sx={{mt:2}}>
    <Typography sx={{mb:1}}><b>Dokumenty ke stažení</b></Typography>
      <Box key={"karta"} sx={{display:"flex",alignItems:"center",gap:2,mb:2,ml:2}}>
        <Box sx={{display:"flex",alignItems:"center",gap:0.7,width:"200px"}}>
          <img src={PDF} alt="pdf" />
          <Typography>{"zelena_karta.pdf"}</Typography>
        </Box>
        <Button size="small" component="label" variant="outlined" startIcon={<FileDownloadOutlinedIcon sx={{color:'#dd9234'}} />} sx={{borderColor:'#dd9234',color:'#dd9234', mr:1 }}>Stáhnout</Button>                
      </Box>    
      <Box key={"info"} sx={{display:"flex",alignItems:"center",gap:2,mb:2,ml:2}}>
        <Box sx={{display:"flex",alignItems:"center",gap:0.7,width:"200px"}}>
          <img src={PDF} alt="pdf" />
          <Typography>{"informace_pojisteni.pdf"}</Typography>
        </Box>
        <Button size="small" component="label" variant="outlined" startIcon={<FileDownloadOutlinedIcon sx={{color:'#dd9234'}} />} sx={{borderColor:'#dd9234',color:'#dd9234', mr:1 }}>Stáhnout</Button>                
      </Box>          
      <Box key={"podm"} sx={{display:"flex",alignItems:"center",gap:2,mb:2,ml:2}}>
        <Box sx={{display:"flex",alignItems:"center",gap:0.7,width:"200px"}}>
          <img src={PDF} alt="pdf" />
          <Typography>{"vseobecne_podminky.pdf"}</Typography>
        </Box>
        <Button size="small" component="label" variant="outlined" startIcon={<FileDownloadOutlinedIcon sx={{color:'#dd9234'}} />} sx={{borderColor:'#dd9234',color:'#dd9234', mr:1 }}>Stáhnout</Button>                
      </Box>    
  <Box sx={{display:"flex", alignItems:"center", gap:2,mt:4}}>
    <TextField
      label={"Email"}
      id="outlined-controlled"
      defaultValue={props.email}
    />
    <Button component="label" variant="outlined" startIcon={<EmailOutlinedIcon sx={{color:'#dd9234'}} />} sx={{borderColor:'#dd9234',color:'#dd9234', mr:1 }}>Odeslat dokumenty na email</Button>                
  </Box>
  </Box>
  :
  <></>}
    </TableContainer>

  </VnitrniBox>  
</AccordionDetails> 
</Accordion>
);
}


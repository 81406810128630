import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { PageLayout } from "./components/PageLayout";
import { ObsahPrihlasen } from "./components/ObsahPrihlasen";
import { ObsahNeprihlasen } from "./components/ObsahNeprihlasen";
import './App-0.0.2.css';

function App() {
    return (
    //     <PageLayout>
    //     <AuthenticatedTemplate>
    //         <ObsahPrihlasen/>
    //     </AuthenticatedTemplate>
    //     <UnauthenticatedTemplate>
    //         <ObsahNeprihlasen/>
    //     </UnauthenticatedTemplate>
    // </PageLayout>    
<div>
<AuthenticatedTemplate>
    <PageLayout>    
        <ObsahPrihlasen/>
    </PageLayout>    
</AuthenticatedTemplate>
<UnauthenticatedTemplate>
    <PageLayout>    
        <ObsahNeprihlasen/>
    </PageLayout>    
</UnauthenticatedTemplate>
</div>
    );
}

export default App;
import * as React from 'react';
import { Typography, Box, Link,Container } from '@mui/material';
import { useMsal } from "@azure/msal-react";

export const ObsahNeprihlasen = () => {
  const { instance} = useMsal();

  function handleLoginClick() {
    instance.loginRedirect().catch(e => {
          console.log(e);

    });        
  }

 return(
<Container maxWidth="lg" sx={{mt:1,pt:1,pb:1, bgcolor: "#d9d9d9",borderTopLeftRadius: '16px',borderTopRightRadius:'16px',minHeight:"90vh"}}>
    <Typography>Nejste přihlášeni do aplikace WEBUNIKALK. <br/>
    <Link component="button" onClick={handleLoginClick}>Přihlásit se</Link>
    </Typography>
{/* <Container maxWidth="lg" sx={{}}> */}
        
</Container>
  );
}
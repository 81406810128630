import {Alert} from '@mui/material';

export function NedostupnaSluzba() {
    return (
        <Alert severity="error" sx={{mb:1}}>Služba je dočasně nedostupná. Kontaktujte IT oddělení.</Alert>
    );
}

export function NeocekavanaChyba(props) {
    // console.log(props.chyba);
    return (
        <Alert severity="error" sx={{mb:1}}>{props.chyba}</Alert>    
    );
}

export function DokumentyOK() {
    // console.log(props.chyba);
    return (
        <Alert severity="success" sx={{p:0,fontSize:"0.8rem"}}>Úspěšně nahráno na server!</Alert>
    );
}